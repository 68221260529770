header {
  text-align: center;
}
.App-logo {
  height: 80px;
}

.App-header {
  background-color: #222;
  height: auto;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#cookiebar a,#cookiebar span {
 	margin-right: 1em
}
#cookiebar {
	border-top: 1px solid #188CC4;
	box-sizing: border-box;
	position: fixed;
	left: 0;
	width: 100%;
	background-color: #188CC4;
	padding: 8px 16px;
	text-align: center;
	font-size: 13px;
	color: #fff;
	line-height: 2;
	z-index: 9999
}
#cookiebar.top {
	top: 0
}
#cookiebar.bottom {
	bottom: 0
}
#cookiebar-desc {
 	display: inline-block;
	font-weight: 500;
}
#cookiebar button {
	height: 28px;
	padding: 0 12px;
	background-color: #188CC4;
	color: #fff;
	cursor: pointer;
	font-weight: 700;
	line-height: 26px;
	border: 2px solid #FFF;
    border-radius: 3px;
}
#cookiebar a {
	color: #fff;
	text-decoration: underline;
	font-weight: 500;
}
#cookiebar a:hover {
 	text-decoration: none
}

/* Help text for input fields */

.ui.form p {
	margin: -12px 0 0 0;
	font-size: 0.8em;
	color: #7e7e7e;
}